<template>
  <div class="page--mask-station play-quiz" :class="{ 'state-start': state.start, 'state-finish': state.finish }">
    <router-link v-if="state.start" to="/mask-station/home" class="back-to">BACK</router-link>

    <Questions v-if="state.start" @onChangeState="updateStateToFinish" />
    <StateFinish v-if="state.finish" />
  </div>
</template>

<script>
import Questions from './snippet/questions';
import StateFinish from './snippet/state-finish';

export default {
  name: 'MaskStationPlayQuiz',
  components: {
    Questions,
    StateFinish,
  },
  data() {
    return {
      state: {
        start: true,
        finish: false,
      },
    };
  },
  methods: {
    updateStateToFinish() {
      this.state.start = false;
      this.state.finish = true;
    },
  },
};
</script>
