<template>
  <div class="finish">
    <h2 class="finish__heading">HOREEE!!</h2>
    <p class="finish__copywriting">
      Selamat, Kamu sudah melewati {{ listAnswer.length }} pertanyaan di mini Quiz ini. Kamu berhak buat dapetin 1 buah
      masker dari Sociolla. Silakan hubungi Beauty Assistant kami.
    </p>

    <div v-if="isRewardLoading === false">
      <img :src="reward.image_cover" class="product-image" />
      <h4 class="product-brand-name">{{ reward.manufacturer_name }}</h4>
      <h5 class="product-name">{{ reward.product_name }}</h5>
    </div>
    <div v-else>
      <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
    </div>

    <a href="" class="btn btn-dark btn-finish">SELESAI</a>
  </div>
</template>

<script>
export default {
  name: 'StateFinish',
  data() {
    return {
      reward: '',
      isRewardLoading: false,
    };
  },
  computed: {
    listAnswer() {
      return this.$store.state.maskStation.listAnswer;
    },
  },
  created() {
    this.getReward();
  },
  methods: {
    getReward() {
      this.isRewardLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/face-mask`, {
          params: {
            filter: {
              tags: this.listAnswer,
            },
            limit: 1,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.reward = response.data.data[0];
          } else {
            this.reward = [];
          }
          this.isRewardLoading = false;
        })
        .catch((error) => {
          this.reward = [];
          this.isRewardLoading = false;
        });
    },
  },
};
</script>
