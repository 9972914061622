<template>
  <div class="full">
    <div class="container-mask-station">
      <h2 class="play-quiz__heading">PLAY QUIZ</h2>

      <div v-if="isQuestionLoading === false">
        <div v-for="(item, key) in questions" :key="key" class="question-wrapper" :class="{ show: item.show }">
          <p class="play-quiz__question">{{ item.question }}</p>

          <div class="row">
            <div v-for="(answer, keyAnswer) in item.options" :key="keyAnswer" class="col-xs-6">
              <a href="" class="question" @click.prevent="addAnswered(answer.tags)">
                <img v-if="answer.image" :src="answer.image" class="question__img" />
                <p class="question__answer">{{ answer.option }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
      </div>
    </div>

    <div class="box">
      <p class="box__paraf">{{ onQuestionNumber }} DARI {{ totalQuestions }} PERTAYAAN</p>
      <div class="progress">
        <div class="progress-bar" :style="{ width: progressWidth }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Questions',
  data() {
    return {
      onQuestionNumber: 1,
      isQuestionLoading: false,
      questions: '',
      progressWidth: '0%',
      styleWidth: '',
      totalQuestions: '...',
    };
  },
  watch: {
    questions() {
      if (this.$SO.size(this.questions)) {
        this.styleWidth = 100 / this.totalQuestions;
        this.updateProgressBar();
      }
    },
  },
  created() {
    this.getQuestions();
  },
  methods: {
    addAnswered(answer) {
      this.questions[this.onQuestionNumber - 1].show = false;
      this.onQuestionNumber += 1;

      if (this.onQuestionNumber <= this.totalQuestions) {
        this.questions[this.onQuestionNumber - 1].show = true;
        this.updateProgressBar();
      }

      this.$store.commit('storeAnswer', answer);

      if (this.onQuestionNumber > this.totalQuestions) {
        this.$emit('onChangeState');
      }
    },
    getQuestions() {
      this.isQuestionLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/recommendation-quiz`)
        .then((response) => {
          if (response.status === 200) {
            this.$SO.each(response.data.data, (key, question) => {
              if (key === 0) {
                response.data.data[key].show = true;
              } else {
                response.data.data[key].show = false;
              }
            });

            this.totalQuestions = this.$SO.size(response.data.data);
            this.questions = response.data.data;
          } else {
            this.questions = [];
          }
          this.isQuestionLoading = false;
        })
        .catch((error) => {
          this.questions = [];
          this.isQuestionLoading = false;
        });
    },
    updateProgressBar() {
      this.progressWidth = this.styleWidth * this.onQuestionNumber + '%';
    },
  },
};
</script>
